import React from 'react';
import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { MDBModal, MDBModalBody, MDBModalDialog, MDBModalContent } from 'mdb-react-ui-kit';

import IconTextField from '../../../components/SharedComponents/IconTextField/IconTextField';
import AutoCompleteSearchField from '../../../components/SharedComponents/AutoCompleteSearch/AutoCompleteSearch';
import CancelIcon from '@material-ui/icons/Cancel';
import BasicButton from '../../../components/SharedComponents/BasicButton/BasicButton';
// import ProfilePictureUpload from '../ProfilePicture/ProfilePictureUpload';
// import TalentContainer from '../../../components/Talent/TalentContainer';
// import AgentContainer from '../../../components/Agent/AgentContainer';
// import ProdCompContainer from '../../../components/Production Companies/ProdCompContainer';
// import CrewContainer from '../../../components/Crew/CrewContainer';
// import RepresentativeContainer from '../../../components/Representative/RepresentativeContainer';
import TabComponent from '../Tab/TabComponent';
import { useStyles, agencyNodes, representativeNodes, crewNodes, talentNodes, productionCompanyNodes } from './Config';
import MessageModal from '../../../components/SharedComponents/MessageModal';
import CircularProgress from "@material-ui/core/CircularProgress";
import './Modal.scss';
import { List } from '@material-ui/icons';
//import ReportsContainer from '../../ReportsLayout/Reports/Reports';
import { uploadProfileParams } from '../ProfilePicture/Config'
import * as Constants from '../../../../constants/constants';
import CasterService from '../../../../services/service'
import { withUserContext } from "../../../../contexts/UserContext"
class ModalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: "default",
            tabValue: (this.props?.headerText === "Reports") ? this.props?.defaultTabValue : 0,
            navObj: {},
            list: [],
            showError: false,
            showSuccess: false,
            showReportFail: false,
            showReportNotFound: false,
            showFail: false,
            fieldChanged: false,
            isPosting: false,
            saveTitle: "Success",
            saveEditMessage: "The changes are saved successfully!",
            failTitle: "Failure",
            failMessage: "Saving changes failed!",
            reportFailMessage: "Report Creation failed!",
            reportNotFoundMessage: "Report Data Not Found!",
            unsavedTitle: "Unsaved Changes",
            unsavedMessage: "Are you sure you want to discard your changes?",
            reportTabList: [],
            imgMode: "download",
            isProfileChanged: false,
            profileImg: null,
            imageType: null,
            hideNew: false,
            showSave: false
        }
        this.submitButtonRef = React.createRef();
    }

    componentDidMount() {
       /* if (this.props?.mode === "newCrew" || this.props?.mode === "newTalent") {
            this.setState({
                mode: "new",
                hideNew: true
            })
        }*/
    }

    /*
    setPostFlag = (value) => {
        this.setState({ isPosting: value });
    }

    handleTabChange = (event, value) => {
        this.setState({ tabValue: value });
    }

    onClose = () => {
        if (this.state.fieldChanged && this.handlePermissions()) {
            this.setState({ showError: true });
        } else {
            this.closPopup()
        }
    }

    closPopup = () => {
        this.setState({ mode: "default" });
        this.setState({ tabValue: 0 });
        this.props?.handleClose()
    }

    setModalList = (list) => {
        this.setState({ list: list });
    }

    handleSubmit = () => {
        this.submitButtonRef.current.click();
    }

    fieldChanged = (value, mode = "error") => {
        switch (mode) {
            case "change": return this.setState({ fieldChanged: value });
            case "error": return this.setState({ fieldChanged: value });
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
            case "reportFail": return this.setState({ showReportFail: value });
            case "reportNotFound": return this.setState({ showReportNotFound: value });
        }
    }

    handleSearchSelect = (val) => {
        this.setState({ navObj: val, mode: "edit", imgMode: "download", isProfileChanged: false, profileImg: null, hideNew: true });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.agencyId && state.mode === "default" && typeof props.agencyId === "number") {
            return {
                mode: "edit",
                navObj: { agency_id: props.agencyId }
            }
        }
        if (props.talentId && state.mode === "default" && typeof props.talentId === "number") {
            return {
                mode: "edit",
                navObj: { talent_id: props.talentId }
            }
        }
        if (props.repId && state.mode === "default" && typeof props.repId === "number") {
            return {
                mode: "edit",
                navObj: { representative_id: props.repId }
            }
        }
        if (props.crewId && state.mode === "default" && typeof props.crewId === "number") {
            return {
                mode: "edit",
                navObj: { crew_id: props.crewId }
            }
        }
        if (props.prodCompId && state.mode === "default" && typeof props.prodCompId === "number") {
            return {
                mode: "edit",
                navObj: { production_company_id: props.prodCompId }
            }
        }
      //  /* Code will be integrated later for Reports Page by Pavani 
        if (props.reportsTabList && props.reportsTabList.length > 0 && state.mode === "default") {
            return {
                mode: "edit",
                //  reportTabList: props?.reportsTabList 
            }
        }
        return null
    }

    generateFilePayload = (talentId) => {
        let objectType = this.props?.headerText === "Representatives" ? "Representative" : this.props?.headerText;
        let fileName = `${objectType.toLowerCase()}_${talentId}`
        let tenantName = this.props.userContext?.active_tenant?.tenant_name;
        let uploadPayload = {
            ...uploadProfileParams,
            signedURLType: this.state.imgMode,
            fileCategory: "profileimage",
            tenantName: tenantName,
            objectType: objectType,
            objectId: talentId,
            contentType: this.state.imageType,
            fileName,
        };
        return uploadPayload;
    }
    convertBlobToImg = (blb) => {
        const reader = new FileReader();
        reader.addEventListener('loadend', (e) => {
            const text = e.srcElement.result;
            this.setState({ profileImg: text, imgMode: "download" });
        });
        reader.readAsText(blb);
    }
    handleImgMode(mode) {
        this.setState({ imgMode: mode })
    }
    handleProfileImg(file) {
        this.setState({ profileImg: file })
    }
    handleProfileChange(val) {
        this.setState({ isProfileChanged: val })
    }
    handleImageType(val) {
        this.setState({ imageType: val })
    }
    uploadProfile = (talentId) => {
        let uploadPayload = this.generateFilePayload(talentId);
        let file = this.state.profileImg;
        console.log(uploadPayload);
        CasterService.postData(Constants.CasterServiceBaseUrl + '/preSignedUrlUpload', uploadPayload, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (this.state.imgMode === "upload") {
                    CasterService.uploadFile(response.data, file).then(response => {
                        console.log("File Upload Successful:" + response.data);
                        this.setState({ imgMode: "download", isProfileChanged: false });

                    }, (err) => {
                        this.setState({ imgMode: "download", isProfileChanged: false, profileImg: null });
                        console.log("File Upload Failed:" + err);
                    });
                }
                else {
                    CasterService.downloadFile(response.data).then(response => {
                        console.log("File Download Successful:" + response.data);
                        this.convertBlobToImg(response.data);

                    }, (err) => {
                        console.log("File Download Failed:" + err);
                        this.setState({ profileImg: null, imgMode: 'download' })
                    });
                }

            }, (err) => {
                console.log("Pre Signed Url Failed:" + err);
            });
    }
    getModalContent = () => {
        switch (this.props?.headerText) {
            case 'Talent': return <TalentContainer
                tabValue={this.state.tabValue}
                setPostFlag={this.setPostFlag}
                fieldChanged={this.fieldChanged}
                navObj={this.state.navObj}
                mode={this.state.mode}
                setModalList={this.setModalList}
                navFuns={this.props.navFuns}
                submitButtonRef={this.submitButtonRef}
                isProfileChanged={this.state.isProfileChanged}
                uploadProfile={this.uploadProfile}
                handleTalentCreation={this.props?.handleTalentCreation}
            />;
            case 'Agency': return <AgentContainer
                tabValue={this.state.tabValue}
                fieldChanged={this.fieldChanged}
                setPostFlag={this.setPostFlag}
                navObj={this.state.navObj}
                agencyId={this.props.agencyId}
                mode={this.state.mode}
                setModalList={this.setModalList}
                navFuns={this.props.navFuns}
                submitButtonRef={this.submitButtonRef} />;
            case 'Production Companies': return <ProdCompContainer
                tabValue={this.state.tabValue}
                fieldChanged={this.fieldChanged}
                setPostFlag={this.setPostFlag}
                navObj={this.state.navObj}
                mode={this.state.mode}
                setModalList={this.setModalList}
                navFuns={this.props.navFuns}
                submitButtonRef={this.submitButtonRef} />;
            case 'Crew': return <CrewContainer 
                tabValue={this.state.tabValue}
                setModalList={this.setModalList}
                fieldChanged={this.fieldChanged}
                setPostFlag={this.setPostFlag}
                submitButtonRef={this.submitButtonRef}
                navFuns={this.props.navFuns}
                navObj={this.state.navObj}
                mode={this.state.mode}
                crewId={this.props.crewId}
                isProfileChanged={this.state.isProfileChanged}
                uploadProfile={this.uploadProfile}
                handleNewCrewDetails={this.props.handleNewCrewDetails}
            />;
            case 'Representatives': return <RepresentativeContainer
                setModalList={this.setModalList}
                fieldChanged={this.fieldChanged}
                setPostFlag={this.setPostFlag}
                tabValue={this.state.tabValue}
                submitButtonRef={this.submitButtonRef}
                navFuns={this.props.navFuns}
                navObj={this.state.navObj}
                mode={this.state.mode}
                repId={this.props.repId}
                isProfileChanged={this.state.isProfileChanged}
                uploadProfile={this.uploadProfile}
            />;
            case 'Reports': return <ReportsContainer
                tabValue={this.state.tabValue}
                mode={this.state.mode}
                setPostFlag={this.setPostFlag}
                fieldChanged={this.fieldChanged}
                handleClose={this.closPopup}
                navFuns={this.props.navFuns} />
        }
    }
    getAkaNames = (list) => {
        let akaNames = "";
        if (typeof list === 'string') {
            akaNames = list;
        }
        else {
            list?.map(aka => aka.aka ? (akaNames = akaNames + (akaNames ? ", " : " ") + aka.aka) : null);
        }
        return akaNames;
    }

    handlePermissions = () => {
        let userPermissions = this.props?.userContext?.active_tenant?.permissions;
        let showSave = false;
        switch (this.props.objectType) {
            case 'TALENT':
                showSave = userPermissions?.isModifyTalent ? userPermissions?.isModifyTalent : false
                break;
            case 'AGENCY':
                showSave = userPermissions?.isModifyAgencies ? userPermissions?.isModifyAgencies : false
                break;
            case 'PRODUCTION_COMPANY':
                showSave = userPermissions?.isModifyProdComp ? userPermissions?.isModifyProdComp : false
                break;
            case 'CREW':
                showSave = userPermissions?.isModifyCrew ? userPermissions?.isModifyCrew : false
                break;
            case 'REPRESENTATIVE':
                showSave = userPermissions?.isModifyRepresentatives ? userPermissions?.isModifyRepresentatives : false
                break;
        }
        return showSave;
        // this.setState({ showSave: showSave });
    }
   
    getWidthForModal = () => {
        switch (this.props?.headerText) {
            case 'Talent': return 'talentModalWidth'
            case 'Agency': return 'talentModalWidth'
            case 'Representatives': return 'talentModalWidth'
            case 'Production Companies': return 'prodcompModalWidth'
            case 'Crew': return 'talentModalWidth'
            case 'Reports': return 'reportModalWidth'
            default: return '';
            
        }
    }

    getClasses = () => {
        const { classes } = this.props;
        switch (this.props?.headerText) {
            case 'Reports': return classes.Reports;
            default: return '';
        }
    }
*/
    render() {
        const { classes } = this.props;
        /*const dataNodes = (this.props?.headerText === "Agency") ? agencyNodes :
            (this.props?.headerText === "Representatives") ? representativeNodes :
                (this.props?.headerText === "Crew") ? crewNodes :
                    (this.props?.headerText === "Talent") ? talentNodes :
                        (this.props?.headerText === "Production Companies") ? productionCompanyNodes : {};
        let message = this.state.showError ? this.state.unsavedMessage : this.state.showFail ? this.state.failMessage :
            (this.state.showSuccess && (this.state.mode === "edit")) ? this.state.saveEditMessage :
                (this.state.showSuccess && (this.state.mode === "new")) ? `New ${this.props?.headerText === "Production Companies" ? "Production Company" : this.props?.headerText} has been created!` :
                    this.state.showReportFail ? this.state.reportFailMessage : this.state.showReportNotFound ? this.state.reportNotFoundMessage : "";
        let comfirmModalTitle = this.state.showError ? this.state.unsavedTitle : this.state.showFail ? this.state.failMessage :
            this.state.showSuccess ? this.state.saveTitle : this.state.showReportFail ? this.state.reportFailMessage :
                this.state.showReportNotFound ? this.state.reportNotFoundMessage : "";
                */
        return (
            <div className={"ModalContentContainer "+this.getWidthForModal()}>
                {/* <Modal
                    open={this.props?.open ? this.props?.open : false}
                    onClose={this.onClose}
                    className={classes.modal}
                > */}
                <MDBModal staticBackdrop closeOnEsc={false} tabIndex='-1' show={this.props?.open ? this.props?.open : false}>
                    {/* <Dialog
                    open={this.props?.open ? this.props?.open : false}
                    onClose={this.onClose}
                    scroll="body"
                    aria-aria-labelledby = {"modal-title-"+this.props?.id}>
                    <DialogContent> */}
                    <MDBModalDialog size={this.props?.headerText !== "Reports" ? "lg" : "md"} className={this.props?.headerText === "Reports" && this.props.userContext?.active_tenant?.tenant_name === "Feature Casting" ? this.getClasses() : null} >
                        <MDBModalBody>
                            <MDBModalContent>
                                <div>
                                    <MDBContainer fluid className="ModalContainer padding-hor-12">
                                        {
                                            this.handlePermissions() &&
                                            <MessageModal
                                                open={this.state.showError || this.state.showFail || this.state.showSuccess || this.state.showReportFail || this.state.showReportNotFound || false}
                                                title={comfirmModalTitle}
                                                message={message}
                                                hideCancel={this.state.showFail || this.state.showReportFail || this.state.showSuccess || this.state.showReportNotFound || false}
                                                primaryButtonText={"OK"}
                                                secondaryButtonText={"Cancel"}
                                                onConfirm={this.state.showReportNotFound ? () => this.setState({ showReportNotFound: false }) : () => this.closPopup()}
                                                handleClose={(e) => this.setState({ showError: false, showFail: false, showSuccess: false, showReportFail: false, showReportNotFound: false })} />
                                        }
                                        <MDBRow className="ModalContent">
                                            <MDBCol>
                                                <MDBRow className="ModalHeader">
                                                    <MDBCol md={2} id={"modal-title-" + this.props?.id} className="HeaderText" >{this.props?.headerText}</MDBCol>
                                                    
                                                    <MDBCol md={5} className="HeaderSearchField" >
                                                        {/* {this.props?.headerText !== "Reports" ?
                                                            <AutoCompleteSearchField 
                                                                placeholder={this.props?.searchPlaceholder}
                                                                headerText={this.props?.headerText} dataUrl={this.props.dataUrl}
                                                                disabled={this.props?.disableSearch}
                                                                objectType={this.props.objectType}
                                                                encryptionType={this.props?.headerText === "Talent" ? 2 : 1}
                                                                handleSearchSelect={this.handleSearchSelect}
                                                                searchAlphabetVariant={this.props?.headerText === "Talent" || this.props?.headerText === "Representatives" || this.props?.headerText === "Agency"}>
                                                                </AutoCompleteSearchField>
                                                            : null
                                                        } */}
                                                    </MDBCol>
                                                    <MDBCol md={4} className="Save">
                                                        {/* {this.state.mode !== "default" && this.props?.headerText !== "Reports"
                                                            && this.handlePermissions() ?
                                                            <BasicButton variant="outlined"
                                                                text={this.state.isPosting ? <CircularProgress color="inherit" size={20} />
                                                                    : "Save & Close"}
                                                                type="inline"
                                                                ref={this.submitButtonRef}
                                                                icon={"save"}
                                                                onClick={() => this.handleSubmit()}
                                                            /> : null} */}
                                                    </MDBCol>
                                                    <MDBCol md={1} className="closeIcon" onClick={this.onClose} ><CancelIcon style={{ fill: "white" }} /></MDBCol>
                                                </MDBRow>
                                                <MDBRow className="ModalBody">
                                                    {/* <MDBCol md={this.props?.headerText !== "Reports" ? 2 : 3} className="ModalSideNav">
                                                        {(this.state.mode === "new" || this.state.mode === "edit") && <MDBCol className="ProfileDiv">
                                                            {(this.props?.headerText !== "Agency" &&
                                                                this.props?.headerText !== "Production Companies" && this.props?.headerText !== "Reports") ?
                                                                <MDBRow><ProfilePictureUpload
                                                                    headerText={this.props?.headerText}
                                                                    navObj={this.state.navObj}
                                                                    mode={this.state.mode}
                                                                    imgMode={this.state.imgMode}
                                                                    isProfileChanged={this.state.isProfileChanged}
                                                                    profileImg={this.state.profileImg}
                                                                    handleImgMode={this.handleImgMode.bind(this)}
                                                                    handleProfileChange={this.handleProfileChange.bind(this)}
                                                                    handleProfileImg={this.handleProfileImg.bind(this)}
                                                                    uploadProfile={this.uploadProfile.bind(this)}
                                                                    handleImageType={this.handleImageType.bind(this)}
                                                                    isUpload={true}

                                                                /></MDBRow> : null}
                                                            {(this.props?.headerText === "Agency" || this.props?.headerText === "Production Companies") ?
                                                                <MDBRow className="NameHeading">{this.state.list[dataNodes?.headerName] || ""}</MDBRow> :
                                                                <MDBRow className="NameHeading">{((this.state.list[dataNodes?.headerName] || "") + ' ' + (this.state.list[dataNodes?.lastName] || ""))}</MDBRow>
                                                            }

                                                            {this.props?.headerText !== "Agency" && this.props?.headerText !== "Representatives" && this.props?.headerText !== "Reports" &&
                                                                this.props?.headerText !== "Crew" ?
                                                                <MDBRow className="AkaNames">{this.state.list[dataNodes?.akas] ? this.getAkaNames(this.state.list[dataNodes?.akas]) : ""}</MDBRow> : null}
                                                            {(this.props?.headerText !== "Talent" && this.props?.headerText !== "Reports") && <MDBRow className="Types">{this.state.list[dataNodes?.type] || ""}</MDBRow>}
                                                            {this.props?.headerText !== "Reports" && <MDBRow className="Types">{this.state.list[dataNodes?.headerDetail] || ""}</MDBRow>}
                                                            <MDBRow className={`${this.props?.headerText === "Reports" && 'reportsNavList'}`}> 
                                                                <TabComponent 
                                                                    headerText={this.props?.headerText} 
                                                                    tabList={(this.props?.headerText === "Reports") ? this.props?.reportsTabList : this.props?.tabList} 
                                                                    tabValue={this.state.tabValue} handleTabChange={this.handleTabChange} 
                                                                /> 
                                                            </MDBRow>
                                                            {this.props.userContext?.active_tenant?.tenant_id === 4 && this.props?.headerText === "Reports" ?
                                                                <MDBRow className={`${this.props?.headerText === "Reports" && 'reportsNavList'}`}> 
                                                                    <TabComponent 
                                                                        headerText={this.props?.headerText} 
                                                                        tabList={this.props?.reportsNavList} 
                                                                        tabValue={this.state.tabValue} 
                                                                        handleTabChange={this.handleTabChange} 
                                                                    /> 
                                                                </MDBRow>
                                                                : null}
                                                          
                                                        </MDBCol>}
                                                        <MDBRow className="footer">
                                                            {this.props?.headerText !== "Reports" && !this.state.hideNew ?
                                                                <BasicButton
                                                                    variant="outlined"
                                                                    text={this.props?.addButtonText}
                                                                    onClick={() => this.setState({ mode: "new", hideNew: true })} /> : null}
                                                        </MDBRow>
                                                    </MDBCol> */}
                                                    <MDBCol md={this.props?.headerText !== "Reports" ? 10 : 9} className="Content">
                                                        {this.state.mode === "default" ?
                                                            <MDBRow className="ModalBodyText margin-none" md={12}>
                                                                {this.props?.bodyText ? this.props?.bodyText : ""}
                                                            </MDBRow> : this.getModalContent()}
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                </div> */

                                {/* </Modal> */}
                                {/* </DialogContent>
                </Dialog> */}
                            </MDBModalContent>
                        </MDBModalBody>
                    </MDBModalDialog>
                </MDBModal>
            </div>
        );
    }
}

export default withUserContext(withStyles(useStyles)(ModalComponent));