import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';
import {
    MDBSideNavCat, MDBSideNavMenu, MDBSideNav, MDBSideNavLink, MDBSideNavItem, MDBModal,
    MDBContainer, MDBIcon, MDBBtn
} from "mdb-react-ui-kit";
import {
    BrowserRouter as Router
} from "react-router-dom";
import Routes from '../../route/Routes';
import ErrorBoundary from "../../error-boundary/ErrorBoundary";
import './MainContent.scss';
import ModalComponent from '../Common/DefaultModalLayout/Modal/Modal';
import MainViewModalComponent from '../Common/MainViewModalLayout/Modal/Modal';
import * as Constants from '../../constants/constants';
import CasterService from '../../services/service';
import { withUserContext } from '../../contexts/UserContext';
import { talentNavList, talentFCNavList, agentNavList, productionCompanyList, crewList, representativeNavList } 
from '../../constants/NavList';

export default withUserContext(class MainContent extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "",
            active_route: "",
        }
        this.mainContent = React.createRef();
    }
    
    setActiveTab = (value) => {
        this.setState({ activeTab: value });
    }

    

    componentDidMount() {
        this._isMounted = true;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.active_route !== this.props.active_route) {
            this.props.history.push(this.props.active_route, this.state);
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {
        return (

            <>
                <div className = "caster-tv-jwp">
                    <MDBContainer fluid >
                        <MDBRow className="mainContent">

                            <MDBCol className="w-auto main-content" id="main-content">

                                <div ref={this.mainContent} >
                                    <ErrorBoundary>
                                        <Routes authState={this.props.authState} {...this.props} {...this.state}
                                             />
                                    </ErrorBoundary>
                                </div>
                            </MDBCol >
                        </MDBRow>
                    </MDBContainer>
                    
                </div>
            </>

        );
    }
});

// export default MainContent;